<template>

      
    <b-modal
            ref="modalConfirmar"
            centered
            hide-footer
            size="sm"
            title="Confirmar solicitud de empleado"
            @hidden="closeModalConfirmar"
          >
            <div class="d-block text-center">
      
              <b-form
                autocomplete="off"
                @submit.prevent="onSubmit"
              >
      
                <b-row>
    
    
                    <b-col
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          style="margin-bottom: 10px; "
                          align="center"
                        >
    
                        <b-avatar
                                                size="80"
                                                :src="photoEmpleado"
                                                variant="light-primary"
                                               
                                              >
                                
                                              </b-avatar>
    
                                              <p style="text-align: center;margin:0px">{{ displayNameEmpleado }}</p>
    
    
                        </b-col>
      
                  <b-col
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          style="margin-bottom: 10px;"
                        >
    
    
                        <p class="textLabel" style="text-align: left;margin-top: 0px;margin-bottom: 5px;">
                            Ingrese el cargo del empleado (*):</p>
    
                            <b-form-input
                                     v-model="cargo"
                                     autofocus
                                     placeholder="Ingrese el cargo del empleado"
                                     required
                                     :readonly="isActive"
                                     type="text"
                                   />
    
                        <p class="textLabel" style="text-align: center;margin-top: 10px;margin-bottom: 5px;">
                            Ingrese el sueldo del empleado (*):</p>
    
                                
    
                      <AutoNumericVue
                          v-model="sueldo"
                          required
                          @blur="onBlurMount"
                          type="text" inputmode="decimal"
                          :readonly="isActive"
                          class="autonumeric_input"
                          :options="json_opciones"
                          :placeholder="'$0.00'"
                      ></AutoNumericVue>
    
    
    
                      
                      <p class="textLabel" style="text-align: left;margin-top: 10px;margin-bottom: 5px;">
                            Establezca el porcentaje máximo de adelanto de sueldo (*):</p>
    
                            <b-form-input  v-model="porcentaje" type="range" min="1" max="100"></b-form-input>
    
                            <p style="justify-content: space-between;display: flex;"> <span style="font-size: 12px;">1%</span>
    
                                <b-badge  variant="primary">
                                    <span style="font-size: 15px;">{{ porcentaje}}%</span> 
                                          </b-badge>
                                
                                
                               
                                
                                <span style="font-size: 12px;">100%</span></p>
    
                        </b-col>
      
                  <b-col
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                  >
      
                    <p
                      class="textLabel"
                      style="text-align: center;margin-bottom: 10px;"
                    >
                      Ingrese su código PIN (*): <feather-icon
                                        icon="HelpCircleIcon"
                                        size="16"
                                          v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                        title="Código PIN de seguridad"
                                        style="margin-right: 5px;cursor:pointer;"
                                        /> </p>
      
                    <PincodeInput
                      v-model="pin"
                      placeholder="-"
                      :length="6"
                      :autofocus="false"
                      :secure="true"
                      :disabled="isActive"
                      required
                    />
      
                  </b-col>
      
                  <b-col
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                  >
      
                    <b-button
                      id="btn_confirmar"
                      ref="btn_confirmar"
                      type="submit"
                      variant="primary"
                      :disabled="isActive"
                      block
                    >
      
                      Confirmar solicitud
      
                    </b-button>
      
                  </b-col>
      
                </b-row>
              </b-form>
      
            </div>
      
          </b-modal>
          
          </template>
          
          <script>
          import {
            BButton,  BRow, VBPopover, BCol, BCard, BAvatar, BForm, BFormInput, BBadge
          } from 'bootstrap-vue'
          
          import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';  
          import PincodeInput from 'vue-pincode-input'
          
          
          export default {
            components: {
               BFormInput, 
               
              BForm,
              BBadge,
              AutoNumericVue,
              PincodeInput,
                BAvatar,
              BButton,
              BRow,
              BCol,
              BCard,
      
            },
            directives: {
              'b-popover': VBPopover,
            },
            props: ['userId', 'tokenAuth','id','displayNameEmpleado','photoEmpleado'],
            data() {
          
          
              return {
    
                pin:"",
                cargo:"",
                isActive:false,
                passwordLabel: this.password,
                json_opciones:{},
                sueldo:"",
                porcentaje:1,
    
              }
            },
            computed: {
          
            },
            watch: {
          
            },
            mounted() {
                this.json_opciones={allowDecimalPadding: "false", currencySymbol: "$", decimalCharacterAlternative: ",",decimalCharacter:".",   minimumValue: 0, maximumValue:1000000, modifyValueOnWheel: false };
    
                this.openmodalConfirmar();
    
            
             
             
    
               
          
            },
            methods: {
    
             
    
                onBlurMount(){
        
    
                        if(Number(this.sueldo) === Number("0")){
    
                            this.sueldo="";
    
                        }
    
                    },
    
                closeModalConfirmar(){
                    this.$eventBus.$emit('reiniciarModalConfirmar');
                },
              openmodalConfirmar(){
              
                this.pin="";
                this.cargo="";
                this.sueldo="";
                this.porcentaje=1;
               this.$refs.modalConfirmar.show()
    
              },
    
         onSubmit(event) {
          event.preventDefault();
    
    
    
            this.isActive=true;
          
            const local_json = {
               userId: this.userId, cargo: this.cargo, pin: this.pin, id: this.id, sueldo: this.sueldo, porcentaje:this.porcentaje
              }
    
            
    
    
    
            const local_string = JSON.stringify(local_json)
      
            const dataEncripted = this.$encryptBackoffice.encrypt(local_string)
     
            document.getElementById('btn_confirmar').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Confirmando'
    
         
          this.$https.post('/locals/aprobarEmpleado/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
            document.getElementById('btn_confirmar').innerHTML = 'Confirmar solicitud';
            this.isActive=false;
          
        
          if (response.data.code == 200) {
    
            this.$eventBus.$emit('reiniciarSolicitudesNavBar');    
          this.$eventBus.$emit('reiniciarSolicitudesIndex');  
         
            this.$refs.modalConfirmar.hide();
    
            this.$toast.success(response.data.message, {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            });
    
          
    
          } else {
    
            this.$toast.error(response.data.message, {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
    
          if (response.data.code == 401) {
    
          
    
            localStorage.removeItem('userData')
    
    
            this.$router.push({ name: 'auth-login' })
          }else{
    
            if (response.data.code == 404) {
                this.$refs.modalConfirmar.hide();
                this.$eventBus.$emit('reiniciarSolicitudesNavBar');    
          this.$eventBus.$emit('reiniciarSolicitudesIndex');   
                    
            
    
            }
    
          } 
          }
          }).catch(error => {
            this.$toast.error("Ocurrio un error " + error, {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
          })
    
              
    
    
        },
             
       
          
             
             
          
            },
          }
          </script>
          
          <style lang="scss">
    
    
          </style>
          