<template>
  <div
    class="app-content content"
    :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass]"
  >
    <div @click="ocultar()" class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >

      <div class="content-body">
        <transition
          :name="routerTransition"
          mode="out-in"
        >
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>

import store from '@/store'
export default {
  components: {

  },
  setup() {

    return {
      routerTransition:"fade", contentWidth:null,
    }
  },
  methods: {

    ocultar(){

       store.commit('app/TOGGLE_OVERLAY', false)
    }
  }
}
</script>

<style>

</style>
