<template>
  <div
    class="horizontal-layout horizontal-menu navbar-sticky footer-fixed"
    :data-col="null"
    style="height:inherit"
  >

    <!-- NAVBAR -->
    <b-navbar
    id="menu"
    style="display: inline-block;     padding: 5px 0px 5px 0px;"

      :toggleable="false"
      class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"

    >
      <slot
        name="navbar"
      >

      <b-row>
        <b-col cols="3" sm="3" md="2" lg="1" xl="1"  order="1"
              order-sm="1"
              order-md="1"
              order-lg="1"
              order-xl="1" style="display: flex;">
          <img style="margin:auto" class="logoSVG_home" />
        </b-col>

        <b-col cols="12" sm="12" md="6" lg="8" xl="8" order="3"
              order-sm="3"
              order-md="2"
              order-lg="2"
              order-xl="2" style="display:flex">

              <div class="horizontal-menu-wrapper" style="margin:auto">

<div
v-if="!isMobile"
  id="menutop"
  class="header-navbar navbar-expand-sm navbar navbar-horizontal navbar-light navbar-shadow menu-border "

>
  <horizontal-nav-menu />
</div>

<RingBottomNavigation
        id="menuBottom"
        v-if="isMobile"
        :display-name="displayName"
        :is-profile-verificated="isProfileVerificated"
        :user-id="userId"
        v-model="selected"
        class="addMargin"
        :options="options"
        :border-color="borderColor"
        :title-color="titleColor"
        :icon-color="iconColor"
        :background-color="backgroundColor"
        :foreground-color="foregroundColor"
      />

</div>

</b-col>

<b-col cols="9" sm="9" md="4" lg="3" xl="3"  order="2"
              order-sm="2"
              order-md="3"
              order-lg="3"
              order-xl="3">
  <app-navbar-horizontal-layout />
</b-col>

      </b-row>

       
      </slot>
    </b-navbar>
    <!--/ NAVBAR -->



    <!-- /Vertical Nav Menu Overlay -->

    <!-- CONTENT -->
    <!-- CONTENT TYPE: Left -->
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <component
        :is="layoutContentRenderer"
        :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
      >
        <template
          v-for="(index, name) in $scopedSlots"
          v-slot:[name]="data"
         
        >
          <slot
            :name="name"
            v-bind="data"
            
          />
        </template>
      </component>
    </transition>
    <!--/ Content -->
    <!--/ CONTENT -->





 

  </div>
</template>

<script>

import AppNavbarHorizontalLayout from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayout.vue'
import { BNavbar, BRow,BCol } from 'bootstrap-vue'

import  RingBottomNavigation  from '@core/layouts/bottom-navigation-vue/src/components/RingBottomNavigation.vue'

// Content Renderer
import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import HorizontalNavMenu from './components/horizontal-nav-menu/HorizontalNavMenu.vue'

// Vertical Menu
/* eslint-disable import/order */

/* eslint-enable import/order */

export default {
  components: {
    RingBottomNavigation,
    AppNavbarHorizontalLayout,
    BRow,
    HorizontalNavMenu,
    BNavbar,
    BCol,
    // Content Renderer
    LayoutContentRendererDefault,

    // Vertical Menu

  },
  computed: {
    layoutContentRenderer() {
      return 'layout-content-renderer-default'
    },
  },
  mounted() {

    
    this.$eventBus.$on('primerMenu1', () => {
 
 this.selected = 0;



})

this.$eventBus.$on('segundoMenu1', () => {

this.selected = 1;


})

   this.$eventBus.$on('tercerMenu1', () => {
    
    

     this.selected = 2


 
})

this.$eventBus.$on('cuartoMenu1', () => {
    
    

    this.selected = 3



})

this.$eventBus.$on('quintoMenu1', () => {

 this.selected = 4;


})

 this.$eventBus.$on('sextoMenu1', () => {
  
   this.selected = 5;
 

})



  this.$eventBus.$on('reiniciarMenu1', () => {


 this.selected = -1;


 
})




  },
  beforeDestroy() {

  },
  data() {

    const datos_json = JSON.parse(this.$userGlobal);

const foregroundColor = '#000'
const backgroundColor = '#fff'

const iconColor = '#00838C'
const borderColor = '#00838C'
const titleColor= '#00838C'


const selected = 0
const options = [
  {
    id: 1,
    icon: 'HomeIcon',
    title: 'Inicio',
    path: { name: 'dashboard-analytics' },
  },
  { id: 2, icon: '', title: 'Billeteras', path: { name: 'wallets' }, },
  {
    id: 3,
    icon: 'ImageIcon',
    title: 'Nfts',
    path: { name: 'nfts' },
  },
  { id: 4, icon: 'BookIcon', title: 'Empresas', path: { name: 'negocio' }, },
   { id: 5, icon: 'ShoppingCartIcon', title: 'Marketplace', path: { name: 'marketplace' }, },
  { id: 6, icon: 'MenuIcon',  title: 'Otros',  path: { name: 'otros' }, },
]

  

    return {

      isMobile:this.$isMobile,
      displayName:datos_json.displayName,
      userId:datos_json.userId,
      isProfileVerificated:datos_json.isProfileVerificated,
      selected:selected,
      options:options,
      borderColor:borderColor,
      titleColor:titleColor,
      backgroundColor:backgroundColor,
      foregroundColor:foregroundColor,
      iconColor:iconColor,
      // Navbar
      navbarMenuTypeClass:"fixed-top",

      // Router Transition
      routerTransition: "fade",





    }
  },
  created() {

    let mode=localStorage.getItem('skin');


    if(mode === "light"){

                        
      document.body.classList.remove('dark-layout')


      }else{

      document.body.classList.add('dark-layout')



    }

  

  },
  methods: {

  },
}
</script>

<style lang="scss">







</style>
