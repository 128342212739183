<template>
  <div class="navbar-container d-flex content align-items-center background_menu">

    <modal-confirmar v-if="openModalConfirmar" :key="componentKeyModalConfirmar" :photo-empleado="photoEmpleado" :display-name-empleado="displayNameEmpleado" :user-id="userId" :token-auth="tokenAuth" :id="id"  />
    <modal-roomies v-if="showModalRoomie" :key="componentKeyModalRoomie" :user-id="userId" :token-auth="tokenAuth" :roomie="roomie"   />


    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <li v-if="isMobile" class="nav-item nav-search margin_icon"  @click="showCodigoQR = true">

        <b-link class="icon_dashboard" > 

              <b-avatar
              size="35"
              variant="light-primary"
              style="cursor:pointer;">

              <span class="d-flex align-items-center">


                <svg  class="icons_colors"  width="18" height="18" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"><path d="M4 4h4V2H2v6h2zM4 16H2v6h6v-2H4zm16 4h-4v2h6v-6h-2zM16 4h4v4h2V2h-6z"/><path d="M5 11h6V5H5v6zm2-4h2v2H7V7zM5 19h6v-6H5v6zm2-4h2v2H7v-2zM19 5h-6v6h6V5zm-2 4h-2V7h2v2zm-4 4h2v2h-2zm2 2h2v2h-2zm2 2h2v2h-2zm0-4h2v2h-2z"/></svg>


              </span>

              </b-avatar>

              </b-link>

        </li>

      <li v-else v-b-tooltip.hover.bottom="'Pagar con QR'" class="nav-item nav-search margin_icon"  @click="showCodigoQR = true">

        <b-link class="icon_dashboard" > 

            <b-avatar
            size="35"
            variant="light-primary"
            style="cursor:pointer;">

            <span class="d-flex align-items-center">


              <svg  class="icons_colors"  width="18" height="18" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"><path d="M4 4h4V2H2v6h2zM4 16H2v6h6v-2H4zm16 4h-4v2h6v-6h-2zM16 4h4v4h2V2h-6z"/><path d="M5 11h6V5H5v6zm2-4h2v2H7V7zM5 19h6v-6H5v6zm2-4h2v2H7v-2zM19 5h-6v6h6V5zm-2 4h-2V7h2v2zm-4 4h2v2h-2zm2 2h2v2h-2zm2 2h2v2h-2zm0-4h2v2h-2z"/></svg>
        

            </span>

            </b-avatar>

            </b-link>
            </li>
            
            <solicitudes :key="componentKeySolicitudes" :is-mobile="isMobile"  :user-id="userId" :token-auth="tokenAuth" />
      <codigo-qr v-if="showCodigoQR"  :display-name-user="displayName" :is-mobile="isMobile" :user-id="userId" :alias="alias" :phone="phone" :email="email" :photo-user="photoUser" :token-auth="tokenAuth"/>
      <li class="nav-item nav-search margin_icon">

          <!-- Icon -->
          <a
            href="javascript:void(0)"
            class="nav-link nav-link-search"
            @click="showSearchBar = true"
          >
          <b-link class="icon_dashboard"> 

              <b-avatar
              size="35"
              variant="light-primary"
              style="cursor:pointer;">

              <span class="d-flex align-items-center">


                <feather-icon
              icon="SearchIcon"
              size="18"
              class="color_icon"
            />

              </span>

              </b-avatar>

              </b-link>

          </a>
            </li>

      <search-bar v-if="showSearchBar" />
      <notification-dropdown :key="componentKeyNotificacionesNavBar" :is-mobile="isMobile"  :user-id="userId" :token-auth="tokenAuth" />
      <user-dropdown  v-if="!isMobile"  :promo-code="promoCode" :user-id="userId" :display-name="displayName" :alias="alias" :is-profile-verificated="isProfileVerificated" :token-auth="tokenAuth" />
    </b-navbar-nav>

   

  </div>
</template>

<script>
import {
  BNavbarNav, BLink, BAvatar, VBTooltip,VBPopover,
} from 'bootstrap-vue'

import ModalConfirmar from './ModalConfirmar.vue'
export default {
  components: {
    NotificationDropdown: () => import('./components/NotificationDropdown.vue'),
    Solicitudes: () => import('./components/Solicitudes.vue'),
    SearchBar: () => import('./components/SearchBar.vue'),
    UserDropdown: () => import('./components/UserDropdown.vue'),
    CodigoQr: () => import('./components/CodigoQr.vue'),
    ModalRoomies: () => import('../../../../views/pages/others/ModalRoomies.vue'),

    ModalConfirmar,
    // Navbar Components
    BNavbarNav,
    BLink, BAvatar,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  directives: {
          'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  data() {

    const datos_json = JSON.parse(this.$userGlobal);


    return {
      roomie:"",
      isMobile:this.$isMobile,
      userId:datos_json.userId,
      email:datos_json.email,
      photoUser:datos_json.photoUser,
      phone:datos_json.phone,
      promoCode:datos_json.promoCode,
      showCodigoQR:false,
      showSearchBar:false,
      displayName:datos_json.displayName,
      alias:datos_json.alias,
      tokenAuth:this.$tokenGlobal,
      componentKeyNotificacionesNavBar:100,
      componentKeySolicitudes:200,
      isProfileVerificated:datos_json.isProfileVerificated,
    
      showModalRoomie:false,
                componentKeyModalRoomie:0,

      id:"",
      displayNameEmpleado:"",
      photoEmpleado:"",
      openModalConfirmar:false,
      componentKeyModalConfirmar:300,
    }
  },
  mounted() {


    this.$eventBus.$on('openModalRoomie', (roomie) => {
        this.roomie = roomie
        this.showModalRoomie=true;
      })

    this.$eventBus.$on('reiniciarModalRoomie', () => {
                this.componentKeyModalRoomie += 1
                this.showModalRoomie=false;
              });


    this.$eventBus.$on('reiniciarNotificacionesNavBar', () => {
      this.componentKeyNotificacionesNavBar += 1
    });

    this.$eventBus.$on('reiniciarSolicitudesNavBar', () => {
      this.componentKeySolicitudes += 1
    });

    

    this.$eventBus.$on('reiniciarCodigoQR', () => {
      this.showCodigoQR = false;
    });

    this.$eventBus.$on('reiniciarSearchBar', () => {
      this.showSearchBar = false;
    });

    this.$eventBus.$on('eliminarSolicitud', (displayName, id) => {

        this.eliminar(displayName, id);


        })

        this.$eventBus.$on('confirmarSolicitud', (id, displayNameEmpleado, photo) => {

        this.confirmar(id, displayNameEmpleado, photo);


        });

        this.$eventBus.$on('reiniciarModalConfirmar', () => {
      this.componentKeyModalConfirmar += 1
      this.openModalConfirmar = false;
    });


  },

  beforeDestroy() {

  },
  methods: {

    confirmar(id, displayNameEmpleado, photo){

        this.id=id;
        this.displayNameEmpleado=displayNameEmpleado;
        this.photoEmpleado=photo;

        this.openModalConfirmar=true;




        },


    eliminar(displayName,id){

      this.$swal({
      title: "Eliminar solicitud de ingreso",
      text: "¿Seguro que deseas eliminar la solicitud de "+displayName+"?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Eliminar solicitud",
      cancelButtonText: 'Cancelar',
      customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-outline-secondary ml-1',
      },
      buttonsStyling: false,
      showLoaderOnConfirm: true,
      preConfirm: () => {

      return this.$https.post('/locals/deleteSolicitud/', { tokenAuth: this.tokenAuth, userId: this.userId, id:id }).then(response => response.data).catch(error => {
          this.$swal.showValidationMessage(
          error,
          )
      })
      },
      allowOutsideClick: () => !this.$swal.isLoading(),
      }).then(result => {

      this.$swal.close()

      if(result.isConfirmed){


      if (result.value.code == 200) {

          this.$eventBus.$emit('reiniciarSolicitudesNavBar');    
          this.$eventBus.$emit('reiniciarSolicitudesIndex');  
          
                  this.$toast.success(result.value.message, {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                  });





              } else {
                  this.$toast.error(result.value.message, {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                  })

                  if (result.data.code == 401) {
                  
                  localStorage.removeItem('userData')


                  // Redirect to login page
                  this.$router.push({ name: 'auth-login' })
                  }
              }

      }





      })

      },

  },

}
</script>
