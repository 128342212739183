<template>

  <div class="rg-btn-container-foreground" :style="cssVariables">
      <div
        v-for="(button, index) in localOptions"
        :key="`ring-btn-${index}`"
        class="rg-btn-container"
        @click="handleButtonClick(button, index)"
        v-on:dblclick="dobleClick(index)"
      >
        <div class="rg-btn-item">
          <div
            :class="[
              { 'rg-btn-border': button.selected },
              { 'rg-btn-border-deselect': button.deselect }
            ]"
          />
          <div
            :class="[
              'rg-btn-icon',
              { 'rg-btn-icon-active': button.selected },
              { 'rg-btn-icon-deselect': button.deselect }
            ]"
          >
  
            <slot name="icon" :props="button" v-if="index == 1">
  
         
  
                 <svg width="30" height="30" viewBox="0 0 1034 1034" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path fill="currentcolor" d="M497 177q-95 1-179.5 41.5T173 330q-62 75-85 169-28 112 4 222 31 107 111 185 82 80 194 108t222-4q107-32 185-111 80-82 108-194t-4-223q-32-106-111-184-82-81-194-108-53-14-106-13zM483 325l46 12-19 75 38 8 18-74 47 11-19 77q49 17 72 40 27 28 21 67-8 57-59 69 33 17 44 43 13 29-1 70-18 50-62 64-37 11-103-1l-19 77-47-12 19-76-37-9-19 76-47-11 20-78-94-24 23-53 17 5q17 4 17 3 15 4 21-11l31-122 5 1-5-1 21-88q2-21-21-27l-34-8v0l12-50 65 16h-1l30 8zm15 142-23 93 7 2q41 11 64 10 41 0 49-32t-27-51q-19-11-61-20zM463 607l-26 102 9 3q50 13 77 13 49 0 58-35t-34-57q-23-12-73-24z" /></svg>
  
  
              
            </slot>





  
            <slot name="icon" :props="button" v-if="index == 3">
  
         
            
              <svg  width="21" height="21"  class="icono3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 461.305 461.305" ><g id="XMLID_819_"><g><g><path d="M365.764 229.834c-12.702 9.473-28.37 14.931-45.037 14.931-15.848.0-31.677-4.969-45.037-14.931-12.702 9.473-28.369 14.931-45.037 14.931-15.845.0-31.675-4.968-45.037-14.931-12.704 9.474-28.372 14.931-45.037 14.931-15.877.0-31.703-4.987-45.037-14.931-12.706 9.474-28.372 14.931-45.037 14.931-13.187.0-25.589-3.412-36.385-9.385v210.053c0 5.572 4.517 10.089 10.089 10.089h159.095V299.309c0-5.572 4.517-10.089 10.089-10.089h74.521c5.572.0 10.089 4.517 10.089 10.089v156.213h159.094c5.572.0 10.089-4.517 10.089-10.089V235.38c-10.796 5.973-23.198 9.385-36.385 9.385C394.988 244.765 379.152 239.816 365.764 229.834zM46.013 310.135c0-7.979 6.468-14.446 14.446-14.446h73.695c7.979.0 14.447 6.468 14.447 14.446v8.95H46.013V310.135zm102.589 84.55c-.001 7.978-6.469 14.446-14.448 14.446H60.46c-7.979.0-14.446-6.468-14.446-14.446v-45.71h102.588v45.71zm266.488.0c0 7.978-6.468 14.446-14.447 14.446h-73.695c-7.979.0-14.446-6.468-14.446-14.446v-45.71H415.09v45.71zm0-84.55v8.95H312.502v-8.95c0-7.979 6.468-14.446 14.446-14.446h73.695C408.622 295.689 415.09 302.157 415.09 310.135z"/><path d="M50.504 219.857c19.668.0 36.697-11.249 45.037-27.658 8.341 16.408 25.37 27.658 45.037 27.658 19.668.0 36.697-11.25 45.038-27.658 8.34 16.408 25.369 27.658 45.037 27.658s36.697-11.25 45.037-27.658c8.34 16.408 25.37 27.658 45.037 27.658 19.668.0 36.697-11.249 45.037-27.658 8.341 16.408 25.37 27.658 45.037 27.658 27.893.0 50.504-22.612 50.504-50.504.001-9.165-37.568-140.631-37.568-140.631-3.614-13.528-15.868-22.939-29.871-22.939H68.403c-13.929.0-26.139 9.314-29.819 22.748C36.958 34.464.0 159.952.0 169.353c0 27.893 22.611 50.504 50.504 50.504z"/></g></g></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>
       


          </slot>
          
         
            <slot name="icon" :props="button" v-else-if="index == 5">
  
              <img v-if="isProfileVerificated" title="Usuario verificado"  alt="Usuario verificado" class="badgeVerificacionMobile" src="/img/verificadoFinal.svg" />
    <img v-else alt="Usuario no verificado"  title="Usuario no verificado" class="badgeVerificacionMobile" src="/img/no_verificadoFinal.svg" />

  
                 <b-avatar
                  size="28"
                  variant="light-primary"
                >

                {{ name }}
  
                </b-avatar>
  
              
            </slot>
  
                 <slot name="icon" :props="button" v-else>
  
          
  
                 <feather-icon
                  :icon="button.icon"
                  size="25"
                />
  
              
            </slot>
  
            <div v-if="button.badge > 0" class="rg-btn-badge" />
          </div>
  
          <div
            :class="['rg-btn-title', { 'rg-btn-title-active': button.selected }]"
          >
            <slot name="title" :props="button">
              {{ button.title }}
            </slot>
          </div>
        </div>
      </div>
    </div>
   
   </template>
   
   <script>
  import { BAvatar } from 'bootstrap-vue'
   

  
   export default {
     components: {
      BAvatar,
     },
     props: ['value','options','userId','isProfileVerificated','displayName', 'iconColor', 'titleColor','borderColor', 'backgroundColor', 'badgeColor', 'replaceRoute'],
     data() {
 
      let name= this.displayName.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()
 
       return {
        name:name,
          prevSelected: null,
          currSelected: null,
          localOptions: [],
          enableWatch: true,
          selected: this.value,
       }
     },
     computed: {
      cssVariables() {
        const styles = {
          '--border-color': this.borderColor,
          '--icon-color': this.iconColor,
          '--background-color': this.backgroundColor,
          '--title-color': this.titleColor,
          '--badge-color': this.badgeColor
        };
  
        return styles;
      }
   
     },
     watch: {

   
  
      selected(newVal, oldVal){
        this.selected=newVal;
         

          /*
  
          if (newVal != oldVal && this.enableWatch) {
            let target = this.localOptions.findIndex(
              (option) => option.id == newVal
            );
  
          
  
            if (target > -1) {
     
              this.handleButtonClick(this.localOptions[target], target);
            }
          }

          */
  
      }

    
  
      
     },  created(){
  
     },
     mounted() {
   
    

      this.$eventBus.$on('primerMenu2', () => {
         
         
     
         this.selected = 1
    
         let target = this.localOptions.findIndex(
              (option) => option.id == this.selected
            );
  
          
  
            if (target > -1) {
     
              this.handleButtonClick(this.localOptions[target], target);
            }
   
     
   })

   this.$eventBus.$on('segundoMenu2', () => {
         
         
     
         this.selected = 2
    
         let target = this.localOptions.findIndex(
              (option) => option.id == this.selected
            );
  
          
  
            if (target > -1) {
     
              this.handleButtonClick(this.localOptions[target], target);
            }
   
     
   })

      this.$eventBus.$on('tercerMenu2', () => {
         
         
     
         this.selected = 3
    
         let target = this.localOptions.findIndex(
              (option) => option.id == this.selected
            );
  
          
  
            if (target > -1) {
     
              this.handleButtonClick(this.localOptions[target], target);
            }
   
     
   })

   this.$eventBus.$on('cuartoMenu2', () => {
         
         
     
         this.selected = 4
    
         let target = this.localOptions.findIndex(
              (option) => option.id == this.selected
            );
  
          
  
            if (target > -1) {
     
              this.handleButtonClick(this.localOptions[target], target);
            }
   
     
   })

   this.$eventBus.$on('quintoMenu2', () => {
         
         
     
         this.selected = 5
    
         let target = this.localOptions.findIndex(
              (option) => option.id == this.selected
            );
  
          
  
            if (target > -1) {
     
              this.handleButtonClick(this.localOptions[target], target);
            }
   
     
   })

   this.$eventBus.$on('sextoMenu2', () => {
         
         
     
         this.selected = 6
    
         let target = this.localOptions.findIndex(
              (option) => option.id == this.selected
            );
  
          
  
            if (target > -1) {
     
              this.handleButtonClick(this.localOptions[target], target);
            }
   
     
   })

   this.$eventBus.$on('reiniciarMenu2', () => {



         let target = this.localOptions.findIndex(
              (option) => option.id == this.selected
            );

            if (target > -1) {

              if ('$set' in this) {
                  this.$set(this.localOptions, target, {
                    ...this.localOptions[target],
                    selected: false
                  });
                } else {
                  this.localOptions[target].selected = false;
                }

                this.$emit('update', -1);

         
          }

           

            this.selected = -1;
            this.prevSelected= null;
          this.currSelected= null;
  

   
     
   })



      this.localOptions = this.options.slice();
  
  
  
      const index = this.localOptions.findIndex(
        (item) =>
          item.id == this.selected ||
          (item.path || {}).name == (this.$route || {}).name
      );
  

  
      if (index > -1) {
     
        this.currSelected = index;
        this.prevSelected = index;
  
        if ('$set' in this) {
          this.$set(this.localOptions, index, {
            ...this.localOptions[index],
            selected: true
          });
        } else {
          this.localOptions[index].selected = true;
        }
      }

     },
     methods: {
    
      dobleClick(index){
  
  
          switch (index) {
          case 0:
          this.$eventBus.$emit('reiniciarHome')
          break
          case 1:
          this.$eventBus.$emit('reiniciarWallets')
  
          break
          case 2:
          this.$eventBus.$emit('reiniciarListadoNfts')
  
          break
              case 3:
              this.$eventBus.$emit('reiniciarIndexNegocio')
  
              
          break
  
          case 4:
  
          this.$eventBus.$emit('reiniciarMarketplace')
  
  
          break
          case 5:
  
          this.$eventBus.$emit('reiniciarOtros')

          break
          }
  
  
  
          },
          handleButtonClick(button, index) {


           
  
          if (index === this.currSelected) {
          return;
          }
          this.currSelected = index;
  
          if (this.prevSelected !== null) {
          const temp = this.prevSelected;
          setTimeout(() => {
          this.localOptions[temp].deselect = false;
          }, 100);
  
          this.localOptions[this.prevSelected].selected = false;
          this.localOptions[this.prevSelected].deselect = true;
          }
  
          if ('$set' in this) {
          this.$set(this.localOptions, index, {
          ...this.localOptions[index],
          selected: true
          });
          } else {
          this.localOptions[index].selected = true;
          }
  
          this.prevSelected = this.currSelected;
          this.updateValue(button);
          },
          updateValue(button) {
           
          this.$emit('update', button.id);
  
          this.enableWatch = false;
          setTimeout(() => {
          this.enableWatch = true;
          }, 0);
  
          if (button.path && Object.keys(button.path).length) {
          this.$router[!this.replaceRoute ? 'push' : 'replace'](
          button.path
          ).catch(() => {});
          }
          }
  
   
     },
   }
   </script>
   
   <style scoped>
  
  
      .rg-btn-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        flex-basis: 100%;
        transition: all 0.3s;
        box-sizing: border-box;
        padding-bottom: 10px;
      }
      
      @media (min-width: 576px) {
        .rg-btn-container {
          cursor: pointer;
        }
      }
      
      .rg-btn-item {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          position: relative;
          margin-top: -5px;
          margin-right: 10px;
      }
      
      
      
      
      
      .rg-btn-icon-active {
        color: var(--icon-color);
        animation:none;
      }
      
      .rg-btn-icon-deselect {
        animation: none;
      }
      
      
      
      .rg-btn-border-deselect {
        position: absolute;
        top: 2px !important;
        height: 3px;
        background-color: var(--border-color);
      
      }
      
      .rg-btn-badge {
        position: absolute;
        top: -1px;
        right: -4px;
        width: 6px;
        height: 6px;
        border-radius: 100px;
        background-color: var(--badge-color);
      }
      
      
      
      .rg-btn-title-active {
        color: var(--title-color);
            font-weight: bold !important;
      }
      
      @keyframes ring-border-top {
        0% {
          width: 5px;
        }
        100% {
          width: 90%;
        }
      }
      
      @keyframes ring-border-top-deselect {
        0% {
          width: 90%;
        }
        100% {
          width: 0px;
        }
      }
      
      @keyframes ring-button-selected {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.4);
        }
        100% {
          transform: scale(1.3);
        }
      }
      
      @keyframes ring-button-deselect {
        0% {
          transform: scale(1.3);
        }
        100% {
          transform: scale(1);
        }
      }

      
.active-label{
  cursor: pointer;
}

.dropdown-toggle::after {
    border: none !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    padding: 0;
    margin: 0;
}

.btn-container_foreground{
  z-index: 10 !important;
}

.rg-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-basis: 100%;
    transition: all 0.3s;
    box-sizing: border-box;
    padding-bottom: 0 !important;
    cursor: pointer;

}

.addMargin{

      top: 52px;
      left: 0px;
}

      </style>
   